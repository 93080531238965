<template>
    <div class="login_container">
        <v-img contain class="logo" src="@/assets/logo.png"></v-img>
        <v-card class="login_card" elevation="2">
            <v-card-title>Entrar</v-card-title>
            <v-card-subtitle>Entre com suas informações de login</v-card-subtitle>

            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-text-field v-model="email" label="Email" outlined hide-details="auto"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field v-model="password" type="password" label="Senha" outlined hide-details="auto"></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn depressed large color="#f39200" @click.native="login">
                    Entrar
                </v-btn>
            </v-card-actions>
        </v-card>

        <div class="new-account">
            <v-btn text class="new-account-button mt-4" color="#f39200" @click.native="dialog = true">Esqueci minha senha</v-btn>
        </div>

        <v-dialog v-model="dialog" max-width="500">
            <v-card>
                <v-card-title class="text-h5" style="word-break: break-word;">
                    Envio de senha
                </v-card-title>
                <v-card-text>Digite seu e-mail abaixo para receber uma senha temporária.</v-card-text>
                <v-card-text>
                    <v-col cols="12">
                        <v-text-field v-model="email2" label="Email" outlined hide-details="auto"></v-text-field>
                    </v-col>
                </v-card-text>

                <v-card-actions>
                    <v-btn class="default-button mb-4" text color="#f39200" @click="dialog = false">
                        Cancelar
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn class="default-button mb-4" color="#f39200" @click="dialog = false; sendNewPassword()">
                        Enviar senha
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
  
<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";
//import { emitToastr } from "@/lib/Utils";

export default {
    name: "LoginView",

    data() {
        return {
            email: "",
            email2: "",
            password: "",
            remember: true,
            dialog: false
        };
    },

    mounted() {
        const token = Api.getToken();
        if (token) this.$router.push("/");
    },

    components: {},

    methods: {
        async login() {
            const resp = await Api.login(this.email, this.password, this.remember);
            if (resp && resp.login_ok) {
                this.$root.$refs.global.updateUser(resp.user);
                this.$router.push("/");
                return;
            }

            if (resp && resp.error && resp.error.message) {
                emitToastr('error', resp.error.message)
                return;
            }
        },

        async sendNewPassword() {
            const resp = await Api.sendNewPassword(this.email2);
            if (resp && resp.error && resp.message) return emitToastr('error', resp.message);
            if (resp && !resp.error && resp.message) return emitToastr('success', resp.message);
        },
    },
};
</script>
  
<style scoped>
.login_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    align-content: center;
    align-items: center;
    background: black;
}

.logo {
    max-width: 400px;
    max-height: 250px;
}

@media only screen and (max-width: 768px) {
    .login_container {
        text-align: center;
        padding: 8%;
    }

}
</style>
  