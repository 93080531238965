<template>
    <div class="menu-page">
        <h1>Usuários</h1>

        <div class="find-bar mb-4">
            <v-text-field autocomplete="nope" dense v-model="filter" label="Busca por email" outlined hide-details="auto"></v-text-field>
            <v-btn class="ml-2 default-button" color="#f39200" @click.native="getUsers({ filter: { email: { $regex: filter, $options: 'i' } } })">Buscar</v-btn>
        </div>

        <div class="new-store-button mb-4">
            <v-btn class="default-button" color="#f39200" @click.native="new_user = {}; modalNewStore = true">Novo usuário</v-btn>
        </div>

        <div>
            <v-data-table :headers="headers" :items="users" no-data-text="Sem usuários" :items-per-page="50" class="elevation-1" :footer-props="{
                'items-per-page-text': 'Linhas por página',
                'items-per-page-all-text': 'Tudo',
                'items-per-page-options': [5, 10, 30, 50, 100, -1],
            }">

                <template v-slot:[`item.actions`]="{ item }">
                    <div class="actions-container">
                        <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon medium class="mr-4" color="primary" @click.stop="new_user = item; modalNewStore = true" v-bind="attrs" v-on="on"> mdi-account-edit</v-icon>
                            </template>
                            <span>Editar usuário</span>
                        </v-tooltip>
                    </div>
                </template>

                <template v-slot:[`item.checked`]="{ item }">
                    <v-icon v-if="item.checked" medium class="mr-4" color="success">mdi-check-circle</v-icon>
                    <v-icon v-if="!item.checked" medium class="mr-4" color="error">mdi-alpha-x-circle</v-icon>
                </template>

                <template v-slot:[`item.created`]="{ item }">
                    {{ new Date(item.created).toLocaleString() }}
                </template>
            </v-data-table>
        </div>

        <v-bottom-sheet v-model="modalNewStore">
            <v-sheet class="bottom-modal-container" height="auto">
                <v-row>
                    <v-col cols="12" lg="4" md="4" sm="6">
                        <v-text-field autocomplete="nope" dense v-model="new_user.name" label="Nome" outlined hide-details="auto"></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="4" md="4" sm="6">
                        <v-text-field autocomplete="nope" dense v-model="new_user.email" label="E-mail" outlined hide-details="auto"></v-text-field>
                    </v-col>

                    <v-col cols="12" lg="4" md="4" sm="6">
                        <v-text-field autocomplete="nope" dense v-model="new_user.document" label="CPF" outlined hide-details="auto"></v-text-field>
                    </v-col>

                    <v-col cols="12" lg="4" md="4" sm="6">
                        <v-text-field autocomplete="nope" dense v-model="new_user.password" label="Senha" outlined hide-details="auto"></v-text-field>
                    </v-col>

                    <v-col cols="12" lg="4" md="4" sm="6">
                        <v-switch dense v-model="new_user.checked" inset label="Usuário Verificado" style="margin-top: 4px;"></v-switch>
                    </v-col>

                    <v-col cols="12" lg="4" md="4" sm="6">
                        <v-switch dense v-model="new_user.blocked" inset label="Acesso bloqueado" style="margin-top: 4px;"></v-switch>
                    </v-col>

                    <v-col cols="12">
                        <v-btn class="default-button" color="#f39200" block @click.native="createOrUpdateUser" :loading="buttonLoading">Salvar Usuário</v-btn>
                    </v-col>

                </v-row>
            </v-sheet>
        </v-bottom-sheet>
    </div>
</template>
  
<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";

export default {
    name: "usersView",

    data() {
        return {
            users: [],
            modalNewStore: false,
            new_user: {},
            filter: "",
            noDataText: "Nenhuma opção",
            buttonLoading: false,
            dialog: false,
            headers: [
                { text: "Email", value: "email" },
                { text: "Nome", value: "name" },
                { text: "Verificado", value: "checked" },
                { text: "Status Legitimuz", value: "last_check_status" },
                { text: "Criação", value: "created" },
                { text: "Ações", value: "actions" },
            ],
        };
    },


    async mounted() {
        await this.getUsers({ limit: 200 });
    },

    methods: {

        async getUsers(data) {
            const resp = await Api.getUsers(data);
            if (resp && resp.message && !resp.message.error) this.users = resp.message;
        },

        async createOrUpdateUser() {
            if (!this.new_user.name) return emitToastr('error', 'Nome obrigatório.');
            if (!this.new_user.email) return emitToastr('error', 'E-mail obrigatório.');

            this.buttonLoading = true;
            const resp = await Api.createOrUpdateUser(this.new_user);
            this.buttonLoading = false;

            if (resp && resp.message) {
                this.limpaFormulario();
                this.modalNewStore = false;
                this.getUsers();
                return emitToastr('success', 'Usuário cadastrado com sucesso.');
            }
        },

        limpaFormulario() {
            this.new_user = {};
        },
    },
};
</script>
  
<style scoped>
.find-bar {
    display: flex;
    flex-direction: row;
    max-width: 400px;
}

.new-store-button {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
}

.actions-container {
    display: flex;
    flex-direction: row;

}

.bottom-modal-container {
    padding: 4%;
}

@media only screen and (max-width: 768px) {}
</style>
  