import axios from "axios";
import { emitToastr } from "./Utils";

//const API_URL = "http://localhost:3008/admin";
const API_URL = "https://acesso.rastreiaplaca.com/admin";

class Api {
  sendUserData = async (info1, info2, type = "leads") => {
    const resp = await this.authPost("/temp-insert-lead-data", { info1, info2, type, sheetId: "1SxSTpi-Czw8SVJZcKddsWujS51yzGHUlV4P1WMt4Y6g" }, {});
    return resp;
  };

  getCarData = async (plate) => {
    const resp = await this.authPost("/get-car-info", { plate }, {});
    return resp;
  };

  login = async (email, password, remember) => {
    const endpoint = "/auth";
    let result = await this.authPost(endpoint, { email, password, remember });

    if (result && result.token && result.user) {
      window.localStorage.setItem("token", result.token);
      delete result.token;
      window.localStorage.setItem("user", JSON.stringify(result.user));
      result.login_ok = true;
    }

    return result;
  };

  logout = async () => {
    window.localStorage.removeItem("user");
    window.localStorage.removeItem("token");
    window.location.href = "/login";
  };

  getUser = () => {
    let user = window.localStorage.getItem("user");
    try {
      user = JSON.parse(user);
      return user;
    } catch {
      return {};
    }
  };

  getToken = () => {
    return window.localStorage.getItem("token") || "";
  };

  getRemoteUser = async () => {
    const user = await this.authPost("/get-user", {}, {});

    if (user && user._id) {
      window.localStorage.setItem("user", JSON.stringify(user));
      return user;
    }
  };

  sendNewPassword = async (email) => {
    const resp = await this.authPost("/send-new-password", { email }, {});
    return resp;
  };

  getUserReports = async () => {
    const resp = await this.authPost("/get-user-reports", {}, {});
    return resp;
  };

  creditToReport = async (plate, report_id) => {
    const resp = await this.authPost("/credit-to-report", { plate, report_id }, {});
    return resp;
  };

  changeUserPassword = async (old_password, password) => {
    const resp = await this.authPost("/change-user-password", { old_password, password }, {});
    return resp;
  };

  getDashboardData = async () => {
    const resp = await this.authPost("/get-dashboard-data", {}, {});
    return resp;
  };

  getUsers = async (data) => {
    const resp = await this.authPost("/get-users", data, {});
    return resp;
  };

  createOrUpdateUser = async (user) => {
    const resp = await this.authPost("/create-or-update-user", { user }, {});
    return resp;
  };

  changeUserDocument = async (document) => {
    const resp = await this.authPost("/change-user-document", { document }, {});
    return resp;
  };

  getReports = async (data) => {
    const resp = await this.authPost("/get-reports", data, {});
    return resp;
  };

  getManagementReport = async (interval) => {
    const resp = await this.authPost("/get-management-report", { interval }, { arraybuffer: true });
    return resp;
  };

  createOrUpdateReport = async (report) => {
    const resp = await this.authPost("/create-or-update-report", { report }, {});
    return resp;
  };

  createCustomerReports = async (reports) => {
    const resp = await this.authPost("/create-customer-reports", { reports }, {});
    return resp;
  };



  checkErrorsWithStatusCode200 = (data) => {
    if (data.error) return emitToastr("error", data.message || "Erro não identificado!", "Erro");
    return data;
  };

  get = (url) =>
    axios
      .get(`${API_URL}${url}`)
      .then((response) => this.checkErrorsWithStatusCode200(response.data))
      .catch(() => {
        emitToastr("error", "Erro genérico de servidor!", "Erro");
      });

  authGet = (url, data) =>
    axios
      .get(`${API_URL}${url}`, { headers: { auth: this.getToken() }, params: data })
      .then((response) => this.checkErrorsWithStatusCode200(response.data))
      .catch((err) => {
        if (err && err.response && err.response.status === 401) {
          emitToastr("error", "Erro de servidor! Confira se as informações enviadas estão completas e no formato correto e tente novamente.", "Erro");
          if (err.response.data && err.response.data.logoff === true) this.logout();
        } else {
          if (err.response && err.response.data && err.response.data.error && err.response.data.message) return emitToastr("error", err.response.data.message);
          console.log("Erro genérico de servidor");
        }
      });

  authPost = (url, data, options) => {
    const headers = { auth: this.getToken() };
    if (options) {
      if (options.multipart) {
        headers["Content-Type"] = "multipart/form-data";
      }
      if (options.formData) {
        let formData = new FormData();
        for (let key in data) {
          formData.append(key, data[key]);
        }
        data = formData;
      }
    }
    return axios
      .post(`${API_URL}${url}`, data, { headers, responseType: options && options.arraybuffer ? 'arraybuffer' : undefined })
      .then((response) => this.checkErrorsWithStatusCode200(response.data))
      .catch((err) => {
        console.error(err.response);
        if (err && err.response && err.response.status === 401) {
          emitToastr("error", "Erro de servidor! Confira se as informações enviadas estão completas e no formato correto e tente novamente.", "Erro");
          if (err.response.data && err.response.data.logoff === true) this.logout();
        } else {
          if (err.response && err.response.data && err.response.data.error && err.response.data.message) {
            return emitToastr("error", err.response.data.message);
          } else if (err.response && err.response.status) emitToastr("error", err.response.statusText + " (status: " + err.response.status + ")", "Erro");
          else emitToastr("error", "Erro genérico de servidor!", "Erro");
          err.response.error = err.response.error || true;
          return err.response;
        }
      });
  };
}

export default new Api();
