<template>
    <div class="menu-page" v-if="user._id">
        <h1>Relatórios</h1>

        <div class="find-bar mb-4">
            <v-text-field autocomplete="nope" dense v-model="filter" label="Busca por placa" outlined hide-details="auto"></v-text-field>
            <v-btn class="ml-2 default-button" color="#f39200" @click.native="getReports({ filter: { plate: { $regex: filter, $options: 'i' } } })">Buscar</v-btn>
        </div>

        <div class="new-store-button mb-4">
            <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn class="default-button ml-2" color="#f39200" :loading="loading" v-bind="attrs" v-on="on">Relatório gerencial</v-btn>
                </template>
                <v-date-picker locale="pt-BR" range v-model="date" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">
                        Cancelar
                    </v-btn>
                    <v-btn text color="primary" @click="getManagementReport(date)">
                        Salvar
                    </v-btn>
                </v-date-picker>
            </v-menu>

            <v-btn class="default-button ml-2" color="#f39200" @click.native="plate = ''; modalNewReport = true">Nova consulta</v-btn>
            <v-btn class="default-button ml-2" color="#f39200" @click.native="qtd = 1; customer = undefined; modalNewReportCustomer = true" v-if="user.superadmin">Criar créditos para cliente</v-btn>
        </div>

        <div>
            <v-data-table :headers="headers" :items="reports" no-data-text="Sem relatórios" :items-per-page="50" class="elevation-1" :footer-props="{
                'items-per-page-text': 'Linhas por página',
                'items-per-page-all-text': 'Tudo',
                'items-per-page-options': [5, 10, 30, 50, 100, -1],
            }">

                <template v-slot:[`item.created`]="{ item }">
                    {{ new Date(item.created).toLocaleString() }}
                </template>

                <template v-slot:[`item.plate`]="{ item }">
                    {{ item.plate ? item.plate.toUpperCase() : '' }}
                </template>

                <template v-slot:[`item.status`]="{ item }">
                    {{ (item.plate && item.quali_api_uuid && item.status == 'novo') ? 'sem resultados' : item.status }}
                </template>

                <template v-slot:[`item.url`]="{ item }">
                    <v-btn v-if="item.status == 'pronto'" small class="primary" @click.native="openReport(item.url)">Ver relatório</v-btn>
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                    <v-btn v-if="item.status == 'erro'" small class="error ma-2" @click.native="reportTryAgain(item)">Gerar mesma placa</v-btn>
                    <v-btn v-if="item.status == 'erro'" small class="error ma-2" @click.native="reportConvertToNew(item)">Converter para crédito</v-btn>
                </template>
            </v-data-table>
        </div>

        <v-bottom-sheet v-model="modalNewReport">
            <v-sheet class="bottom-modal-container" height="auto">
                <v-row>
                    <v-col cols="12">
                        <v-text-field autocomplete="nope" v-mask="'AAA-#N##'" dense v-model="plate" label="Placa" outlined hide-details="auto"></v-text-field>
                    </v-col>

                    <v-col cols="12">
                        <v-btn class="default-button" color="#f39200" block @click.native="createNewReport" :loading="buttonLoading">Criar relatório</v-btn>
                    </v-col>
                </v-row>
            </v-sheet>
        </v-bottom-sheet>

        <v-bottom-sheet v-model="modalNewReportCustomer">
            <v-sheet class="bottom-modal-container" height="auto">
                <v-row>
                    <v-col cols="6">
                        <v-text-field autocomplete="nope" type="number" dense v-model="qtd" label="Quantidade" outlined hide-details="auto"></v-text-field>
                    </v-col>

                    <v-col cols="6">
                        <v-autocomplete no-data-text="Usuário não encontrado" dense :items="users" clearable v-model="customer" item-text="email" item-value="_id" label="E-mail do usuário" outlined hide-details="auto"></v-autocomplete>
                    </v-col>

                    <v-col cols="12">
                        <v-btn class="default-button" color="#f39200" block @click.native="createCustomerReports" :loading="buttonLoading">Criar</v-btn>
                    </v-col>
                </v-row>
            </v-sheet>
        </v-bottom-sheet>
    </div>
</template>
  
<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";
import { saveAs } from 'file-saver';

export default {
    name: "reportsView",

    data() {
        return {
            reports: [],
            modalNewReport: false,
            modalNewReportCustomer: false,
            plate: '',
            user: {},
            users: [],
            filter: "",
            qtd: 1,
            customer: undefined,
            noDataText: "Nenhuma opção",
            buttonLoading: false,
            dialog: false,
            menu: false,
            date: null,
            loading: false,
            headers: [
                { text: "Criação", value: "created" },
                { text: "Placa", value: "plate" },
                { text: "Email", value: "user.email" },
                { text: "Status", value: "status" },
                { text: "Link", value: "url" },
                { text: "Ações", value: "actions" },
            ],
        };
    },


    async mounted() {
        await this.getReports({ limit: 300 });
        const resp = await Api.getUsers();
        if (!resp.error && resp.message) this.users = resp.message;
        this.user = await Api.getRemoteUser();
    },

    methods: {
        async getManagementReport(date) {
            this.menu = false;
            this.loading = true;
            const resp = await Api.getManagementReport(date);
            this.loading = false;

            const filename = `${new Date().toLocaleString().split('/').join('_').split(',').join('').split(':').join('_')}`

            const blob = new Blob([resp], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            });
            saveAs(blob, `Relatorio_Gerencial_${filename}.xlsx`);
        },

        openReport(url) {
            window.open(url, "_blank");
        },

        async getReports(data) {
            const resp = await Api.getReports(data);
            if (resp && resp.message && !resp.message.error) this.reports = resp.message;
        },

        async createNewReport() {
            const resp = await Api.createOrUpdateReport({ plate: this.plate, status: 'novo' });
            if (resp && resp.message && !resp.message.error) {
                emitToastr("success", "Operação bem sucedida");
                this.modalNewReport = false;
                await this.getReports({ limit: 300 });
            }
        },

        async createCustomerReports() {
            const resp = await Api.createCustomerReports({ customer: this.customer, qtd: this.qtd });
            if (resp && !resp.error && resp.message) {
                emitToastr("success", "Operação bem sucedida");
                this.modalNewReportCustomer = false;
                await this.getReports({ limit: 300 });
            }
        },


        async reportTryAgain(report) {
            report.fails = 0;
            report.status = 'buscando';
            const resp = await Api.createOrUpdateReport(report);
            if (!resp.error) return emitToastr("success", "Operação bem sucedida")
        },

        async reportConvertToNew(report) {
            report.fails = 0;
            report.status = 'novo';
            delete report.url;
            const resp = await Api.createOrUpdateReport(report);
            if (!resp.error) return emitToastr("success", "Operação bem sucedida")
        }
    },
};
</script>
  
<style scoped>
.find-bar {
    display: flex;
    flex-direction: row;
    max-width: 400px;
}

.new-store-button {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
}

.actions-container {
    display: flex;
    flex-direction: row;

}

.bottom-modal-container {
    padding: 4%;
}

@media only screen and (max-width: 768px) {}
</style>
  