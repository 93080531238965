import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import Dashboard from "../views/Dashboard.vue";
import Account from "../views/Account.vue";
import DashboardAdmin from "../views/DashboardAdmin.vue";
import Reports from "../views/Reports.vue";
import Users from "../views/Users.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      layout: "fullscreen",
    },
  },
  /*
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      layout: "fullscreen",
    },
  },
  */
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/dashboard-admin",
    name: "DashboardAdmin",
    component: DashboardAdmin,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/reports",
    name: "Reports",
    component: Reports,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/users",
    name: "Users",
    component: Users,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/minha-conta",
    name: "Account",
    component: Account,
    meta: {
      layout: "menu",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const publicPages = ["/login", "/password-recovery"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("token");

  if (authRequired && !loggedIn) {
    next("/login");
  } else {
    next();
  }
});

export default router;
