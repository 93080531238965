<template>
    <div class="menu-page">
        <h1>Meus relatórios <v-btn v-if="!user.blocked" large class="primary ml-4" @click.native="openPaymentLink">Comprar crédito</v-btn></h1>

        <div v-if="user.last_check_status && (user.last_check_status == 'Iniciado' || user.last_check_status == 'Em andamento' || user.last_check_status == 'Análise Manual')" class="mt-4 mb-4">
            <v-alert prominent type="info">
                <v-row align="center">
                    <v-col class="grow">
                        Análise de identidade com status {{ user.last_check_status }}. {{ user.last_check_status == 'Em andamento' ? 'Finalize a sua verificação de identidade para poder fazer consultas. Você poderá continuar a verificação atual ou tentar novamente.': '' }}
                        <span v-if="user.last_check_status == 'Iniciado'">Se a janela de verificação de identidade não abriu, configure o navegador para permitir abertura de popups ou tente novamente por outro navegador ou dispositivo.</span>
                    </v-col>
                </v-row>
            </v-alert>
        </div>

        <div v-if="user.blocked" class="mt-4 mb-4">
            <v-alert prominent type="error">
                <v-row align="center">
                    <v-col class="grow">
                        Infelizmente sua identidade não pôde ser confirmada, e essa conta ficará temporariamente bloqueada. Por favor entre em contato com o suporte em (48) 9621-2389 (Whatsapp)
                    </v-col>
                    <v-col class="shrink">
                        <v-btn @click.native="openLink">Suporte</v-btn>
                    </v-col>
                </v-row>
            </v-alert>
        </div>
        <div v-if="!user.blocked">
            <v-card v-if="!user.checked && user.last_check_status != 'Análise Manual'" class="mt-4">
                <v-card-title>Verificação de identidade</v-card-title>
                <v-card-subtitle>Antes da sua primeira consulta, é necessário verificar sua identidade. Fique tranquilo, essa etapa é rápida e só acontecerá uma única vez.</v-card-subtitle>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" lg="6" md="6" sm="6">
                            <v-text-field id="legitimuz-hydrate-cpf" type="tel" v-model="user.document" autocomplete="nope" dense label="CPF" outlined hide-details="auto"></v-text-field>
                        </v-col>

                        <v-col cols="12" lg="6" md="6" sm="6">
                            <v-btn id="legitimuz-action-verify" class="default-button" color="#f39200" @click.native="changeUserDocument">{{ (user.last_check_status == 'Iniciado' || user.last_check_status == 'Em andamento') ? 'Tentar novamente' : 'Verificar' }}</v-btn>
                        </v-col>
                    </v-row>


                    <!--
                <input id="legitimuz-hydrate-cpf" type="tel" />
                <button id="legitimuz-action-verify">Verificar</button>
                -->
                </v-card-text>
            </v-card>

            <div class="mt-4 mb-4" v-if="reports.length == 0">
                Você ainda não tem nenhum relatório ou crédito. Compre um crédito para consultar uma placa.
            </div>

            <div class="pa-6 mt-4" v-if="user.checked">
                <v-card class="mb-4" v-for="(item, index) in reports" :key="index">

                    <v-card-text class="pb-0" v-if="!user.admin && item.status == 'novo' && item.plate && item.quali_api_uuid">
                        <v-alert type="info" class="mb-0">
                            {{ `A consulta da placa ${item.plate.toUpperCase()} não retornou resultados. Por isso você recebeu um crédito que poderá ser utilizado para consultar outra placa.` }}
                        </v-alert>
                    </v-card-text>

                    <v-card-title v-if="item.status == 'novo'">Crédito disponível para consulta</v-card-title>
                    <v-card-title v-if="item.status == 'buscando'">{{ item.plate.toUpperCase() }} - Buscando dados - Aguarde</v-card-title>
                    <v-card-title v-if="item.status == 'processando'">{{ item.plate.toUpperCase() }} - Dados encontrados - Processando - Aguarde</v-card-title>
                    <v-card-title v-if="item.status == 'erro'">{{ item.plate.toUpperCase() }} - Erro na busca - Entrar em contato com o suporte</v-card-title>
                    <v-card-title v-if="item.status == 'pronto'">{{ item.plate.toUpperCase() }} - Relatório pronto</v-card-title>
                    <v-card-title v-if="item.status == 'sem resultados'">{{ item.plate.toUpperCase() }} - Consulta sem resultados</v-card-title>

                    <v-card-subtitle>{{ new Date(item.created).toLocaleString() }}</v-card-subtitle>

                    <v-card-text>
                        <v-row v-if="item.status == 'novo'">
                            <v-col cols="12" lg="12" md="12" sm="12">
                                <v-btn class="primary" @click.native="plate = ''; dialog = true; selectedReport = item">Consultar placa agora</v-btn>
                            </v-col>
                        </v-row>

                        <v-row v-if="item.status == 'pronto'">
                            <v-col cols="12" lg="6" md="6" sm="6">
                                <v-btn class="primary" @click.native="openReport(item)">Baixar relatório</v-btn>
                            </v-col>
                        </v-row>

                        <v-row v-if="item.status == 'buscando'">
                            <v-col cols="12" lg="12" md="12" sm="12">
                                <h3>Buscando ocorrências para a placa. Por favor aguarde um momento. Quando o relatório estiver pronto, você será avisado por e-mail.</h3>
                            </v-col>
                        </v-row>

                        <v-row v-if="item.status == 'processando'">
                            <v-col cols="12" lg="12" md="12" sm="12">
                                <h3>Placa com resultados na busca. Por favor aguarde um momento.</h3>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </div>
        </div>

        <v-dialog v-model="dialog" max-width="800">
            <v-card>
                <v-card-title>
                    Consultar placa {{ plate }}
                </v-card-title>

                <v-card-subtitle>
                    Insira a placa no campo abaixo para iniciar a consulta
                </v-card-subtitle>

                <v-card-text>
                    <v-row>
                        <v-col cols="12" lg="6" md="6" sm="6">
                            <v-text-field v-mask="'AAA-#N##'" autocomplete="nope" dense v-model="plate" label="Placa" outlined hide-details="auto"></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="6" md="6" sm="6">
                            <v-btn class="primary" :loading="loading" @click.native="getPreData">Informações do veículo</v-btn>
                        </v-col>
                    </v-row>

                    <v-row class="mt-4 mb-4" v-if="preData">
                        <v-col cols="12">
                            <v-simple-table dense>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left" v-if="preData.marca || preData.MARCA">
                                                Marca
                                            </th>
                                            <th class="text-left" v-if="preData.modelo || preData.MODELO">
                                                Modelo
                                            </th>
                                            <th class="text-left" v-if="preData.ano || preData.anoModelo">
                                                Ano
                                            </th>
                                            <th class="text-left" v-if="preData.chassi">
                                                Chassi
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td v-if="preData.marca || preData.MARCA">{{ preData.marca || preData.MARCA }}</td>
                                            <td v-if="preData.modelo || preData.MODELO">{{ preData.modelo || preData.MODELO }}</td>
                                            <td v-if="preData.ano || preData.anoModelo">{{ preData.ano || preData.anoModelo }}</td>
                                            <td v-if="preData.chassi">{{ preData.chassi }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions class="pb-4">
                    <v-spacer></v-spacer>

                    <v-btn color="error" text @click="preData = {}; dialog = false">
                        Cancelar
                    </v-btn>

                    <v-btn color="primary" :loading="loading" v-if="preData && (preData.MARCA || preData.marca)" @click.native="creditToReport">
                        Consultar {{ plate }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
  
<script>
/* eslint-disable */
import Api from '@/lib/Api';
var timer;
import { emitToastr } from "@/lib/Utils";

export default {
    name: "DashboardView",

    data() {
        return {
            reports: [],
            autoRefreshInterval: 30,
            dialog: null,
            selectedReport: {},
            plate: "",
            preData: {},
            user: {},
            loading: false,
            newDocument: ''
        };
    },

    components: {},

    async mounted() {
        this.user = await Api.getRemoteUser();
        await this.getReports();
        legitimuz.mount();
    },

    created() {
        timer = setInterval(() => {
            this.getReports();
        }, this.autoRefreshInterval * 1000)
    },

    beforeDestroy() {
        clearInterval(timer)
    },

    methods: {
        async creditToReport() {
            this.loading = true;
            const resp = await Api.creditToReport(this.plate, this.selectedReport._id);
            this.loading = false;
            if (!resp.error && resp.message && resp.message == "ok") {
                emitToastr("success", "Relatório iniciado com sucesso. Por favor aguarde seu processamento");
                this.preData = {};
                this.dialog = false;
                setTimeout(() => {
                    this.getReports();
                }, 3000);
            }
        },

        async changeUserDocument() {
            let doc = this.user.document.split('.').join('').split('-').join('').split('/').join('').split(' ').join('');

            if (doc.length != 11) return emitToastr("error", "CPF inválido. Por favor digite apenas os números");
            await Api.changeUserDocument(doc);
        },

        async getReports() {
            const resp = await Api.getUserReports();
            if (!resp.error && resp.message) this.reports = resp.message;
        },

        openReport(report) {
            window.open(report.url, "_blank");
        },

        openPaymentLink() {
            window.open('https://go.pagflexy.com.br/pay/rastreia-placa', "_blank");
        },

        openLink() {
            window.open('https://wa.link/thpny2', "_blank");
        },

        async getPreData() {
            if (!this.plate) return emitToastr("error", "O preenchimento da placa é obrigatório");

            if (this.plate.length != 8 && this.plate.replace('-').length != 7) {
                return emitToastr("error", "Placa em formato incorreto. Por favor, verifique essa informação");
            }

            this.preData = {};
            this.loading = true;
            const resp = await Api.getCarData(this.plate);
            this.loading = false;

            if (!resp.error && resp.message && (resp.message.MARCA || resp.message.marca)) {
                this.preData = resp.message;
            }
            else {
                emitToastr("error", "Erro ao consultar. Verifique a placa inserida e tente novamente mais tarde.");
                return;
            }
        }
    },
};
</script>
  
<style scoped></style>
  