<template>
  <v-app style="background-color: #eaeaea">
    <v-main v-if="$route.meta.layout === 'fullscreen'">
      <router-view></router-view>
    </v-main>

    <div v-if="$route.meta.layout === 'menu'">
      <v-progress-linear app indeterminate color="deep-purple lighten-3" class="loading-bar" v-show="showLoading"></v-progress-linear>

      <v-navigation-drawer class="drawer" v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp" width="270" app>
        <center class="avatar_container mt-6 mb-6">
          <div class="avatar">
            <img src="@/assets/logo.png" width="180" />
          </div>
          <h4 class="icon">{{ user && user.name ? user.name : projectName }}</h4>
        </center>
        <v-divider />

        <v-list dense>


          <router-link to="/minha-conta">
            <v-list-item link>
              <v-list-item-action class="item_icon">
                <v-icon class="icon">mdi-account-outline</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="menu-item">Minha conta</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>

          <router-link to="/">
            <v-list-item link>
              <v-list-item-action class="item_icon">
                <v-icon class="icon">mdi-car-search</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="menu-item">Meus relatórios</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>

          <router-link to="/cadastrar-carro-lojista" v-if="user && user.is_car_dealer">
            <v-list-item link>
              <v-list-item-action class="item_icon">
                <v-icon class="icon">mdi-plus</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="menu-item">Cadastro de carros</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>

          <v-list v-if="user && user.admin">
            <v-list-group>
              <template v-slot:activator>
                <v-list-item-title class="menu-item">Administração</v-list-item-title>
              </template>
              <template v-slot:prependIcon>
                <v-icon class="icon">mdi-cog-outline</v-icon>
              </template>

              <router-link to="/dashboard-admin" v-if="user && user.superadmin">
                <v-list-item>
                  <v-list-item-icon class="item_icon">
                    <v-icon class="icon">mdi-monitor-dashboard</v-icon>
                  </v-list-item-icon>

                  <v-list-item-title class="menu-item">Dashboard</v-list-item-title>
                </v-list-item>
              </router-link>

              <router-link to="/reports">
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon class="icon">mdi-car-search</v-icon>
                  </v-list-item-icon>

                  <v-list-item-title class="menu-item">Relatórios</v-list-item-title>
                </v-list-item>
              </router-link>

              <router-link to="/users">
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon class="icon">mdi-account-multiple</v-icon>
                  </v-list-item-icon>

                  <v-list-item-title class="menu-item">Usuários</v-list-item-title>
                </v-list-item>
              </router-link>
            </v-list-group>
          </v-list>

          <router-link to="#">
            <v-list-item @click.native="logout()">
              <v-list-item-action class="item_icon">
                <v-icon class="icon">mdi-exit-run</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="menu-item">Sair</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
        </v-list>

      </v-navigation-drawer>

      <v-main>
        <v-app-bar color="white" :clipped-left="$vuetify.breakpoint.lgAndUp" dark elevation="2">
          <v-app-bar-nav-icon @click.stop="drawer = !drawer" color="black"></v-app-bar-nav-icon>
          <v-toolbar-title class="toolbar">
            {{ projectName }}
          </v-toolbar-title>
          <v-spacer></v-spacer>

          <v-menu>
            <template #activator="{ on: menu }">
              <v-btn icon v-on="menu">
                <v-icon color="black">mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="$router.push('/minha-conta')">
                <v-list-item-title class="bar-menu-item-title">
                  <v-icon class="mr-3">mdi-account</v-icon>
                  Minha conta
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="logout()">
                <v-list-item-title class="bar-menu-item-title">
                  <v-icon class="mr-3">mdi-exit-run</v-icon>
                  Sair
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-app-bar>
        <v-container fluid class="menu-page-container">
          <router-view fluid />
        </v-container>

        <v-dialog v-model="dialogHelpCenter" max-width="350">
          <v-card>
            <v-card-title class="text-h5">
              Central de Ajuda
            </v-card-title>
            <v-card-text>Oferecemos suporte online via whatsapp. Adicione nosso número e inicie uma conversa.</v-card-text>
            <v-card-text>
              <h3><v-icon color="green" class="mr-2">mdi-whatsapp</v-icon>(48) 8864-5540</h3>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="#f39200" text @click="dialogHelpCenter = false;">
                Ok
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>


        <v-dialog v-model="dialogTerms" fullscreen hide-overlay persistent transition="dialog-bottom-transition" class="details-container">
          <v-card>
            <v-toolbar dark class="bar-car-details">
              <v-toolbar-title>Termos de uso</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn icon dark @click.native="dialogTerms = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>

            <v-card-text class="pt-4" v-html="termos" style="font-size: 24px;"></v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogTips" fullscreen hide-overlay persistent transition="dialog-bottom-transition" class="details-container">
          <v-card>
            <v-toolbar dark class="bar-car-details">
              <v-toolbar-title>Dicas de Segurança</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn icon dark @click.native="dialogTips = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>

            <v-card-text class="pt-4" style="font-size: 16px; line-height: 32px;" v-html="dicas">

            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogAbout" fullscreen hide-overlay persistent transition="dialog-bottom-transition" class="details-container">
          <v-card>
            <v-toolbar dark class="bar-car-details">
              <v-toolbar-title>Sobre o Clube do Carro</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn icon dark @click.native="dialogAbout = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>

            <v-card-text class="pt-4" style="font-size: 16px; line-height: 32px;" v-html="sobre">
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-main>
    </div>
  </v-app>
</template>

<script>
import Api from "@/lib/Api";

export default {
  name: "App",

  data: () => ({
    drawer: false,
    showLoading: false,
    projectName: "Rastreia Placa",
    user: { admin: false, superadmin: false },
    dialogHelpCenter: false,
    dialogTerms: false,
    dialogTips: false,
    dialogAbout: false,
    termos: '',
    dicas: '',
    sobre: '',
  }),

  methods: {
    logout() {
      Api.logout();
    },

    setLoading(loading) {
      this.showLoading = loading;
    },

    updateUser(user) {
      this.user = user;
    }

  },

  created() {
    this.$root.$refs.global = this;
  },


  async mounted() {
    this.user = Api.getUser();

    if (this.user && this.user._id) this.user = await Api.getRemoteUser();
    this.$vuetify.breakpoint.lgAndUp ? (this.drawer = true) : (this.drawer = false);
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}

.bar-menu-item-title {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.menu-page-container {
  background-color: #eaeaea;
  padding: 24px;
}

.toolbar {
  font-weight: 700;
  color: black;
}

.drawer {
  background: rgb(0, 0, 0);

  z-index: 100;
}

.icon {
  color: #b8b8b8;
}

.item_icon {
  margin-right: 16px !important;
}

.logo {
  position: relative;
  margin-top: 30px;
  margin-bottom: 25px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.menu-item {
  color: white;
  font-weight: 700 !important;
  text-overflow: none !important;
}

.loading-bar {
  position: absolute;
  z-index: 99999 !important;
}

.avatar_container {
  margin-bottom: 10px;
}

.avatar {
  margin-top: 15px;
  margin-bottom: 10px;
}

.rotate_icon {
  transform: rotate(90deg);
}

.toolbar-subtitle {
  font-size: 16px;
  font-weight: 500;
}

.bar-car-details {
  background: linear-gradient(145deg, #111127 0%, #1d388b 96%);
  border-radius: 0px !important;
}
</style>
